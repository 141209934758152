<template>
    <v-container>

        <v-card-title class="grey lighten-4 mt-5">
            <v-row dense>
                <v-col cols="12" sm="5"> <b>LISTA RESUMEN ACTIVIDADES</b>
                </v-col>
                <v-col cols="12" sm="1">
                </v-col>
                <v-col cols="12" sm="3"> <v-text-field dense v-model="search" append-icon="mdi-magnify" label="BUSCAR"
                        single-line hide-details />

                </v-col>

                <v-col cols="12" sm="3">
                    <v-btn class="mx-1" small color="primary" dark block @click="downloadHandle">
                        DESCARGAR <v-icon right dark> mdi-arrow-down-box </v-icon>
                    </v-btn>
                </v-col>
            </v-row>

        </v-card-title>
        <v-card-text class="white pa-2">
            <v-row dense>
                <v-col cols="12">
                    <v-data-table multi-sort :search="search" :headers="headers" :items="listaResumen"
                        :loading="loading2" class="elevation-1" :items-per-page="20">

                    </v-data-table>
                </v-col>
            </v-row>
        </v-card-text>
    </v-container>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import { exportXLSX } from '@/utils/genericUtils'

export default {

    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            hourRules: [
                (v) => !!v || 'Hora es requerido',
                (v) => /^(0?[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(v) || 'Formato de hora inválido'
            ],
            itemSelected: null,
            dialog: false,
            loading: false,
            search: null,
            loading2: false,
            headers: [
                { text: 'idOrden', value: 'idOrden' },
                { text: 'TIPO', value: 'type' },
                { text: 'idTicket', value: 'idTicket' },
                { text: 'Producto', value: 'product' },
                { text: 'usuario Asignado', value: 'userAssign' },
                { text: 'Fecha', value: 'dateEnd' },

      


            ]
        }
    },
    computed: {
        ...mapState('users', ['user']),
        ...mapState('inventario', ['listaResumen']),
        ...mapState('sedes', ['custom_products']),


    },
    methods: {
  

        ...mapActions({
            getItemAddInventory: 'inventario/getListaResumenReport'

        }),
        ...mapMutations({
            /*             RESET_FORM: 'clientes/RESET_FORM',
            UPDATE_DATOS: 'clientes/UPDATE_DATOS', */
            OPEN_MODAL: 'mainUI/OPEN_MODAL',
            OPEN_BAR: 'mainUI/OPEN_BAR'
        }),

        downloadHandle() {
            this.loading = true
            const headers = [
                'idOrden',
                'type',
                'idTicket',
                'userAssign',
                'product',
                'dateEnd',
            ]

            const filename = `REPORT_RESUMEN_ACTIVIDADES_${Date.now()}`

            exportXLSX({ headers, campos: headers, arrayData: this.listaResumen }, { filename })
            this.loading = false
        },
    },

    async created() {
        this.loading2 = true;

        await this.getItemAddInventory()


        this.loading2 = false;
    }
}
</script>
